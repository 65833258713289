@import "./config";

.projects-container {
  height: 100%;
  background-color: $projects-background;
  box-shadow: 0 0 11px -3px rgba(173, 185, 202, .6);
  padding-bottom: 60px;

  .projects--header {
    margin: 0 auto;

    h1 {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 35pt;
      font-weight: 700;
      text-align: center;
      padding-top: 25px;
      color: white;
    }
  }

}


@media (min-width: 768px) {
  .projects-container {
    .projects--header {

      h1 {
        font-size: 60pt;
      }
    }
  }
}

@media (min-width: 1024px) {
  .projects-container {
    .projects--header {

      h1 {
        font-size: 70pt;
      }
    }
  }
}
