@import "./config";

.project-container {
  width: 360px;
  height: 725px;
  margin: 5px auto;
  background-color: $project-background;

  .project--header {
    margin: 0 auto;

    h1 {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 20pt;
      font-weight: 700;
      text-align: center;
      padding-top: 25px;
      color: white;
    }
  }

  .projectContent--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .desktopImg--container {
      max-width: 350px;
      height: 225px;
      margin: 0 auto;

      .carousel {

        .carousel-indicators {
          bottom: -35px;
        }
      }

      .desktopImg {
        max-height: 225px;
        max-width: 350px;
        object-fit: contain;
      }
    }

    .project--details {
      width: 360px;
      height: 390px;
      display: flex;
      flex-direction: column;
      margin-top: 25px;

      h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 13pt;
        text-align: center;
        color: white;

      }

      .techStack--icons {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .iconify {
          height: 38px;
          width: 38px;
          margin: 10px 5px 15px 5px;
        }
      }
    }

    .description {
      margin-bottom: 10px;

      p {
        font-family: 'Roboto', sans-serif;
        font-size: 10pt;
        text-align: left;
        color: white;
        height: 183px;
        margin-right: 8px;
      }
    }

    .btns--container {
      display: flex;
      justify-content: center;

      .btn--style {
        background-color: $neon-blue;
        margin: 30px 30px 10px 30px;
        width: 150px;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
}

@media (min-width: 768px) {
  .project-container {
    width: 700px;
    height: 900px;

    .project--header {

      h1 {
        font-size: 25pt;
      }
    }

    .projectContent--container {
      .desktopImg--container {
        max-width: 600px;
        height: 350px;

        .carousel {
          .carousel-indicators {
            bottom: -44px;
          }
        }

        .desktopImg {
          max-height: 350px;
          max-width: 600px;
        }
      }

      .project--details {
        width: 650px;
        height: 420px;
        margin-top: 40px;

        h3 {
          font-size: 15pt;
        }

        .techStack--icons {
          .iconify {
            height: 48px;
            width: 48px;
          }
        }
      }

      .description {
        p {
          font-size: 13pt;
          height: 175px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .project-container {
    width: 1000px;
    height: 525px;

    .project--header {
      h1 {
        font-size: 27pt;
      }
    }

    .projectContent--container {
      flex-direction: row;
      justify-content: space-around;

      .desktopImg--container {
        max-width: 500px;

        .carousel {
          .carousel-indicators {
            bottom: -44px;
          }
        }

        .desktopImg {
          max-height: 345px;
          max-width: 500px;
        }
      }

      .project--details {
        width: 460px;
        height: 390px;
        margin-top: 0;

        h3 {
          font-size: 15pt;
        }

        .techStack--icons {
          .iconify {
            height: 48px;
            width: 48px;
          }
        }
      }

      .description {
        margin-top: 5px;

        p {
          font-size: 12pt;
          height: 200px;
        }
      }
    }
  }
}

