@import "./config";

.terminal {
  width: 97vw;
  height: 400px;
  margin: 20px auto;
}

.terminal__closed {
  height: 0;
  width: 97vw;
  margin: 20px auto;
  @include animation-name(close-terminal);
  @include animation-duration(1s);

  .terminal--body {
    overflow: hidden;
  }

  .terminal--header {
    background-color: $terminal-header;
    width: 0;
    height: 25px;
    opacity: 1;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    @include animation-name(close-header);
    @include animation-duration(1s);
    @include animation-delay(1s);
    @include animation-fill-mode(backwards);
  }

  @include keyframes(close-terminal) {
    0% { height: 400px; }
    100% { height: 0; }
  }

  @include keyframes(close-header) {
    0% { width: inherit; margin: 0 auto;}
    100% { width: 0; margin: 0 auto;}
  }
}

.terminal--header {
  background-color: $terminal-header;
  width: inherit;
  height: 25px;
  opacity: 1;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: flex-start;

  div {
    margin-left: 8px;
    display: flex;
    justify-content: space-around;
    align-self: center;

    div {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin: 0 3px;
    }

    .close-icon {
      background-color: $neon-red;
    }

    .minimize-icon {
      background-color: $neon-yellow;
    }

    .expand-icon {
      background-color: $neon-green;
    }
  }
}

.terminal--body {
  width: inherit;
  height: 100%;
  min-height: 0;
  background-color: $terminal-body;
  opacity: .8;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;

  .command-prompt {
    font-weight: 900;
  }
}

@media (min-width: 768px) {
  .terminal {
    width: 73vw;
    height: 550px;
  }

  .terminal--body {
    opacity: .7;
  }

  .terminal__closed {
    width: 73vw;
  }
}

@media (min-width: 1024px) {
  .terminal {
    width: 60vw;
    height: 450px;
  }

  .terminal--header {
    height: 30px;
  }

  .terminal__closed {
    width: 60vw;

    .terminal--header {
      height: 30px;
    }
  }
}
