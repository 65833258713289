@import "./config";

.landing {
  background-image: url($mobile);
  background-repeat: no-repeat;
  @include background-size(cover);
  background-position: center;
  background-attachment: scroll;
  height: 100vh;

  .mainContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .terminal--container {
    position: relative;
    left: 0;
    top: -185px;
    z-index: 2;
  }
  .name-and-positions {
    position: relative;
    height: auto;
    margin: 0 auto;
    padding-bottom: 35px;
    overflow: hidden;

    .name {
      position: relative;
      left: 0;
      bottom: 0;
      overflow: hidden;
      @include animation-name(render-name);
      @include animation-duration(3s);
      @include animation-delay(2.5s);
      @include animation-fill-mode(backwards);
      @include keyframes(render-name) {
        0% { left: -100vw; bottom: 0; }
        25% { left: 26px; bottom: 0; }
        50% { left: -15px; bottom: 0; }
        75% { left: 10px; bottom: 0; }
        85% { left: -5px; bottom: 0; }
        100% { left: 0; bottom: 0; }
      }
    }

    .name h1 {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 35pt;
      font-weight: 700;
      text-align: center;
      padding-top: 25px;
      color: white;
      text-shadow: 0 1px 7px $neon-orange;
    }

    .positions {
      position: relative;
      left: 0;
      bottom: 0;
      overflow: hidden;
      padding-top: 15px;
      @include animation-name(render-positions);
      @include animation-duration(3s);
      @include animation-delay(7.3s);
      @include animation-fill-mode(backwards);
      @include keyframes(render-positions) {
        0% { left: 100vw; bottom: 0; }
        25% { left: 25px; bottom: 0; }
        50% { left: -15px; bottom: 0; }
        75% { left: 10px; bottom: 0; }
        85% { left: -5px; bottom: 0; }
        100% { left: 0; bottom: 0; }
      }

      h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 17pt;
        font-weight: 500;
        text-align: center;
        color: whitesmoke;
      }
    }
  }

  .emailAddress {
    position: relative;
    display: inline-block;
    height: 32px;
    width: 336px;
    left: 131px;
    bottom: -94px;
    overflow: hidden;
    text-align: center;
    transform: rotate(90deg);
    z-index: 1;
    @include animation-name(render-email);
    @include animation-duration(3s);
    @include animation-delay(18s);
    @include animation-fill-mode(backwards);
    @include keyframes(render-email) {
      0% { left: 131px; bottom: 700px; }
      25% { left: 131px; bottom: -120px; }
      50% { left: 131px; bottom: -70px; }
      75% { left: 131px; bottom: -94px; }
      85% { left: 131px; bottom: -80px; }
      100% { left: 131px; bottom: -94px; }
    }

    a {
      transform: rotate(90deg);
      font-family: 'Roboto', sans-serif;
      font-size: 12pt;
      color: $neon-blue;
      text-decoration: none;
      text-align: center;
    }
  }

  .socialMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    z-index: 1;
    position: relative;
    left: 0;
    bottom: 0;
    @include animation-name(render-socialMedia);
    @include animation-duration(3s);
    @include animation-delay(13s);
    @include animation-fill-mode(backwards);
    @include keyframes(render-socialMedia) {
      0% { left: 0; bottom: 100vh; }
      25% { left: 0; bottom: 0; }
      50% { left: 0; bottom: -20px; }
      75% { left: 0; bottom: 15px; }
      85% { left: 0; bottom: -10px; }
      100% { left: 0; bottom: 0; }
    }

    .iconify {
      color: $neon-red;
      width: 32px;
      height: 32px;
      margin: 10px 0 10px 0;
    }
  }
}

@media (min-width: 768px) {
  .landing {
    background-image: url($tablet);
    background-attachment: fixed;

    .terminal--container {
      left: 16px;
      top: -337px;
      z-index: 0;
    }

    .name-and-positions {
      padding-bottom: 65px;
      width: 75vw;

      .name h1 {
        font-size: 60pt;
      }

      .positions h3 {
        font-size: 23pt;
      }
    }

    .emailAddress {
      height: 32px;
      left: 131px;
      bottom: -137px;
      width: 336px;
      z-index: 1;
      @include keyframes(render-email){
        0% { left: 131px; bottom: 700px; }
        25% { left: 131px; bottom: -160px; }
        50% { left: 131px; bottom: -90px; }
        75% { left: 131px; bottom: -145px; }
        85% { left: 131px; bottom: -120px; }
        100% { left: 131px; bottom: -137px; }
      }

      a {
        font-size: 17pt;
        text-decoration: none;
      }
    }

    .socialMedia {
      margin-left: 20px;
      z-index: 1;

      .iconify {
        width: 50px;
        height: 50px;
        margin: 10px 0 10px 0;
      }
    }
  }
}

@media (min-width: 1024px) {
  .landing{
    background-image: url($desktop);

    .terminal--container{
      position: relative;
      left: 0;
      top: -400px;
    }

    .name-and-positions {
      padding-bottom: 75px;

      .name h1 {
        font-size: 70pt;
      }

      .positions h3 {
        font-size: 30pt;
      }
    }

    .emailAddress {
      height: 32px;
      left: 81px;
      bottom: -155px;
      width: 336px;
      z-index: 100;
      @include keyframes(render-email) {
        0% { left: 81px; bottom: 100vh; }
        25% { left: 81px; bottom: -185px; }
        50% { left: 81px; bottom: -130px; }
        75% { left: 81px; bottom: -170px; }
        85% { left: 81px; bottom: -140px; }
        100% { left: 81px; bottom: -155px; }
      }

      a {
        font-size: 20pt;
      }
    }

    .socialMedia {
      margin-left: 20px;
      z-index: 100;

      .iconify {
        width: 64px;
        height: 64px;
        margin: 10px 0 10px 20px;
      }
    }
  }
}
