/*
 * Colors
 */
$neon-red: #D2335C;
$neon-orange: #FF9948;
$neon-yellow: #9DBF16;
$neon-green: #04BE5B;
$neon-blue: #0082d5;
$neon-purple: #A93ABA;

$terminal-header: #3D4D65;
$terminal-body: #54657E;

$projects-background: #202934;
$project-background: #54657E;

/*
 * Background Image URLs
 */
$mobile: "https://res.cloudinary.com/doaftkgbv/image/upload/v1590434811/mobile-blackLanding_cz8bvz.png";
$tablet: "https://res.cloudinary.com/doaftkgbv/image/upload/v1590434811/tablet-blackLanding_ufndzs.png";
$desktop: "https://res.cloudinary.com/doaftkgbv/image/upload/v1590434978/desktop-blackLanding_silbtf.png";

/*
 * Profile Pic URL
 */
$profile-pic: "https://res.cloudinary.com/doaftkgbv/image/upload/v1590433650/PersonalProfile_tmriaj.png";

/*
 * Animation Mixins
 */
@mixin animation-name($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}

@mixin animation-duration($time) {
  -webkit-animation-duration: $time;
  -moz-animation-duration: $time;
  -o-animation-duration: $time;
  animation-duration: $time;
}

@mixin animation-delay($time) {
  -webkit-animation-delay: $time;
  -moz-animation-delay: $time;
  -o-animation-delay: $time;
  animation-delay: $time;
}

@mixin animation-fill-mode($mode) {
  -webkit-animation-fill-mode: $mode;
  -moz-animation-fill-mode: $mode;
  -o-animation-fill-mode: $mode;
  animation-fill-mode: $mode;
}

@mixin animation($name, $time, $mode) {
  -webkit-animation: $name $time $mode;
  -moz-animation: $name $time $mode;
  -o-animation: $name $time $mode;
  animation: $name $time $mode;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

/*
 * BackgroundImage Mixins
 */
@mixin background-size($behavior) {
  -webkit-background-size: $behavior;
  -moz-background-size: $behavior;
  -o-background-size: $behavior;
  background-size: $behavior;
}

